import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { colors, styles, analytics } from 'midtype-backend';

import Layout from '../layout/Layout';
import Image from '../components/Image';
import BlogPill from '../components/BlogPill';

const Styled = styled.div`
  max-width: 50rem;
  margin: 5rem auto;
  margin-bottom: 2rem;
  padding: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colors.BLACK()};
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  h1 {
    font-size: 2.5rem;
    line-height: initial;
    margin-top: 3.5rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-top: 3rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h4 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  p,
  li {
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    font-family: adobe-caslon-pro, Georgia, 'Times New Roman', Times, serif;
  }
  code,
  pre {
    font-size: 13px;
  }
  pre {
    margin: 2rem 0;
  }
  pre code {
    background: none !important;
  }
  strong {
    font-weight: 600;
    color: ${colors.BLACK()};
  }
  li {
    margin-bottom: 0;
  }
  blockquote {
    margin: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${colors.GRAY_2()};
  }
  blockquote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  figure {
    margin: 2rem 0;
  }
  figure a span {
    margin: 0 0 0.75rem 0 !important;
    box-shadow: ${styles.BOX_SHADOW};
    border-radius: 6px;
    overflow: hidden;
    transition: 250ms all;
    &:hover {
      box-shadow: ${styles.BOX_SHADOW_DARK};
    }
  }
  figcaption {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: ${colors.GRAY_3()};
  }
  ul,
  ol {
    display: block;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  ul li {
    list-style: disc;
  }
  ol li,
  ul li {
    margin-left: 0;
    padding-left: 0.5rem;
  }
  img {
    max-width: 100%;
  }
  .post-title {
    margin: 1rem 0;
  }
  .post-author {
    margin: 1rem 0 2rem 0;
    display: flex;
    align-items: center;
  }
  .post-author__name {
    margin-top: 1rem;
  }
  .post-author__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    overflow: hidden;
  }
  .post-image {
    margin-bottom: 2rem;
    max-height: 30rem;
    overflow: hidden;
    box-shadow: ${styles.BOX_SHADOW};
  }
  .post-image .gatsby-image-wrapper {
    min-height: 100%;
  }
  .gatsby-resp-image-link {
    margin: 2rem 0;
  }
  .content--Insights {
    .gatsby-resp-image-figure {
      width: 100vw;
      padding: 2rem 2rem;
      background: ${colors.GRAY_2(0.3)};
      margin-left: calc(((100vw - 50rem) / -2) - 2rem);
      text-align: center;
    }
    .gatsby-resp-image-wrapper {
      box-shadow: ${styles.BOX_SHADOW};
      margin: auto !important;
    }
  }

  @media screen and (max-width: 1280px) {
    margin: 5rem 0;
    margin-left: calc((100vw - 50rem) / 2 - 10rem);
    .content--Insights {
      .gatsby-resp-image-figure {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 800px) {
    margin: 2rem auto;
    .post-image {
      margin: 0 -2rem 2rem -2rem;
    }
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    h4,
    h5 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    blockquote p {
      font-size: 2rem;
      line-height: 3rem;
    }
    p,
    li {
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }
    .content--Insights {
      .gatsby-resp-image-figure {
        margin-left: -2rem;
        width: 100vw;
      }
    }
  }
`;

const BlogPostTemplate: React.FC<any> = props => {
  useEffect(() => {
    analytics.track('Read Blog Post', {
      title: props.data.markdownRemark.frontmatter.title,
      author: props.data.markdownRemark.frontmatter.author.name
    });
  }, []);
  const {
    title,
    description,
    tag,
    author,
    image,
    published
  } = props.data.markdownRemark.frontmatter;
  const imagePath = props.data.images.edges[0]
    ? props.data.images.edges[0].node.publicURL
    : '';
  return (
    <Layout
      pageTitle={title}
      pageDescription={description}
      hideLines={true}
      image={process.env.GATSBY_MIDTYPE_MARKETING_URL + imagePath}
    >
      <Styled>
        <BlogPill {...tag} />
        <h1 className="post-title">{title}</h1>
        <div className="post-author">
          <div className="post-author__avatar">
            <Image src={author.avatar} />
          </div>
          <h5 className="post-author__name">
            <a href="/">{author.name}</a>{' '}
            <span className="text-gray">on {published}</span>
          </h5>
        </div>
        <div className="post-image">
          <Image src={image} />
        </div>
        <div
          className={`content content--${tag.id}`}
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        />
      </Styled>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $image: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        learn_more
        tag {
          id
          r
          g
          b
        }
        image
        published(formatString: "MMMM Do, YYYY")
        author {
          name
          twitter
          avatar
        }
      }
    }
    images: allFile(filter: { relativePath: { eq: $image } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      limit: 10
      filter: { fields: { slug: { ne: $slug }, template: { eq: "blog" } } }
      sort: { fields: frontmatter___published, order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tag {
            id
            r
            g
            b
          }
          image
        }
      }
    }
  }
`;

export default BlogPostTemplate;
